import { TicketStatusEnum } from "@prisma/client";

import { Badge } from "@/components/tailwind/common/Badge";
import { trpc } from "@/util/trpc";

export const ProcessingTicketNotification = () => {
  const { data: ticketCounts } = trpc.cmsTicket.ticketGroupCounts.useQuery();

  if (!ticketCounts?.[TicketStatusEnum.PROCEEDED]) return null;

  return (
    <Badge
      name={ticketCounts[TicketStatusEnum.ACTIVE].toString()}
      variant="inactive"
    />
  );
};
