import { UserIcon } from "@heroicons/react/solid";
import AlbumIcon from "public/images/icon/album.svg";
import GiftRecordIcon from "public/images/icon/gift-record.svg";
import GiftIcon from "public/images/icon/gift.svg";
import { BiSolidBadgeDollar } from "react-icons/bi";
import { FaCrown } from "react-icons/fa6";
import {
  HiAnnotation,
  HiBadgeCheck,
  HiCreditCard,
  HiExclamation,
} from "react-icons/hi";
import { RiCustomerServiceFill } from "react-icons/ri";

import { ProcessingReportNotification } from "@/features/princess/report/components/ProcessingReportNotification";
import { ProcessingTicketNotification } from "@/features/princess/ticket/ProcessingTicketNotification";
import { PendingVerificationBadge } from "@/features/princess/verification/PendingVerificationBadge";
import { ProjectSettings, ProjectSlug } from "@/settings/types";

export const princess: ProjectSettings = {
  name: "Princess",
  slug: ProjectSlug.Princess,
  iconUrl: "/images/icon/project-icon-princess.png",
  avatarUrl: "/images/icon/project-avatar-princess.png",
  graphqlEndpoint: {
    production: "https://api.princess-dating.com/api/graphql",
    preview: "https://api-preview.princess-dating.com/api/graphql",
    // local: "https://api-preview.princess-dating.com/api/graphql",
    local: "http://localhost:4004/api/graphql",
  },
  allowEmailRegex: {
    production:
      "george@wemakeapp.net|jacky@wemakeapp.net|samuel@wemakeapp.net|andrew@wemakeapp.net|vina@wemakeapp.net|horrychy@gmail.com|jonathan@wemakeapp.net",
    preview: "@wemakeapp.net",
    local: "@wemakeapp.net",
  },
  denyIntrospection: true,
  sidebarCollections: [
    {
      name: "用戶",
      url: "/table/user",
      icon: UserIcon,
    },
    {
      name: "認證",
      url: "/table/verification",
      icon: HiBadgeCheck,
      notification: PendingVerificationBadge,
    },
    {
      name: "訊息紀錄",
      url: "/table/message",
      icon: HiAnnotation,
    },
    {
      name: "客戶服務",
      url: "/table/help",
      icon: RiCustomerServiceFill,
      notification: ProcessingTicketNotification,
    },
    {
      name: "舉報",
      url: "/table/report",
      icon: HiExclamation,
      notification: ProcessingReportNotification,
    },
    {
      name: "相簿",
      url: "/table/album",
      icon: AlbumIcon,
    },
    {
      name: "禮物清單",
      url: "/table/giftList",
      icon: GiftIcon,
    },
    {
      name: "送禮",
      url: "/table/giftRecord",
      icon: GiftRecordIcon,
    },
    {
      name: "訂閱紀錄",
      url: "/table/subscription",
      icon: FaCrown,
    },
    {
      name: "付費活動",
      url: "/table/creditLog",
      icon: BiSolidBadgeDollar,
    },
    {
      name: "帳單紀錄",
      url: "/table/paymentLog",
      icon: HiCreditCard,
    },
  ],
  cloudinaryConfig: {
    cloudName: "dpaqr0jkt",
    uploadPreset: "princess",
  },
};
